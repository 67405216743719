import React from 'react';
import { PageProps, graphql, useStaticQuery } from 'gatsby';

import Layout from '../layouts/Layout';
import JobSearchForm from '../components/JobSearchForm/JobSearchForm';
import TextSection from '../components/TextSection/TextSection';
import FeaturedCareers from '../components/FeaturedCareers/FeaturedCareers';
import Values from '../components/Values/Values';

import menuItems from '../constants/menuItems';
import heroImg from '../images/landing-hero.png';

const IndexPage = (props: PageProps) => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	return (
		<Layout
			location={props.location}
			menuItems={menuItems}
			title={'Coach USA Careers'}
			desc={'Coach USA Careers'}
		>
			<div className={'container mx-auto py-20'}>
				<h1 className={'text-center text-4xl'}>
					<span className={'text-9xl'}>404</span> <br /> Page Not
					Found.
				</h1>
			</div>
		</Layout>
	);
};

export default IndexPage;
